define("in4m-web/controllers/component/index", ["exports", "in4m-web/utils/alerts"], function (_exports, _alerts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filter: null,
    filterOptions: null,
    userConfirmDelete: '',
    showDuplicates: false,
    selectedComponents: null,
    selectedStatus: '',
    selectedDate: '',
    selectedTimeZone: '',
    runImmediately: false,
    runAfter: '',
    casenumber: '',
    fromdaterangepicker: '',
    todaterangepicker: '',
    initializeDatePicker: false,
    profilesLoaded: false,
    fmt: 'MM/DD/YYYY h:mm:ss A',
    init: function init() {
      this._super.apply(this, arguments); // date queries use LT and GT operators. However, the GT operator includes the selected date (greater than midnight) so in the UI we label it 'Greater than or equals'


      this.set('filterOptions', [{
        name: 'UTG Version',
        field: 'UtgVersion',
        type: 'text',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Version",
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Agent Version',
        field: 'AgentVersion',
        type: 'text',
        supportsOperators: true,
        supportsMultiplePredicates: true,
        TypeHint: "Version",
        operatorOptions: [{
          key: "Is Equal to",
          value: "EQ"
        }, {
          key: "Is Not Equal to",
          value: "NEQ"
        }, {
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Less than or Equal to",
          value: "LTE"
        }, {
          key: "Is Greater than",
          value: "GT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GTE"
        }]
      }, {
        name: 'Merchant',
        field: 'ComponentMerchant',
        type: 'text'
      }, {
        name: 'UTG Nickname',
        field: 'ComponentNickname',
        type: 'text'
      }, {
        name: 'Profile',
        field: 'ComponentProfileName',
        type: 'text'
      }, {
        name: 'Profile Version',
        field: 'ComponentProfileVersion',
        type: 'numeric',
        TypeHint: "Integer"
      }, {
        name: 'Location/Serial',
        field: 'ComponentLocation',
        type: 'text'
      }, {
        name: 'Lane',
        field: 'ComponentLane',
        type: 'text'
      }, {
        name: 'File ID',
        field: 'ComponentFileId',
        type: 'text'
      }, {
        name: 'Machine Status',
        field: 'MachineStatus',
        type: 'text'
      }, {
        name: 'UTG Status',
        field: 'UtgStatus',
        type: 'text'
      }, {
        name: 'Last Known Communication',
        field: 'MachineStatusEntityLastKnownCommunication',
        type: 'date',
        supportsOperators: true,
        TypeHint: "DateTime",
        supportsMultiplePredicates: true,
        operatorOptions: [{
          key: "Is Less than",
          value: "LT"
        }, {
          key: "Is Greater than or Equal to",
          value: "GT"
        }]
      }]);
    },
    watchCheckbox: Ember.observer('runImmediately', function () {
      $("#scheduleTasks").data('validator').resetForm();
    }),
    reactUtgListUrl: Ember.computed('session.selectedBusinessEntity', function () {
      return "/react/#/be/".concat(this.get('session.selectedBusinessEntity.id'), "/utgs");
    }),
    isCogHidden: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToViewCog = true;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      var hasPermissionToEditServices = user.hasPermission('canEdit', 'in4mUtgServices', selectedBusinessEntity);
      var hasPermissionToScheduleTasks = user.hasPermission('isEnabled', 'in4mUtgScheduleTasks', selectedBusinessEntity);
      var hasPermissionToSetupPackages = user.hasPermission('isEnabled', 'in4mUtgSetupPackages', selectedBusinessEntity);

      if (hasPermissionToEditServices || hasPermissionToScheduleTasks || hasPermissionToSetupPackages) {
        //checking if the user has atleast one of the above permissions
        hasPermissionToViewCog = false;
      }

      return hasPermissionToViewCog;
    }),
    pageSize: Ember.computed('sessionStorage.pageSize', function () {
      return 0; //trigger server-side max page size logic by setting to zero (This function used to retrieve pageSize from sessionStorage. This value is no hard-coded to trigger load of all data.)
    }),
    pageNumber: Ember.computed('sessionStorage.pageNumber', function () {
      return 0; //trigger server-side max page size logic by setting to zero (This function used to retrieve pageNumber from sessionStorage. This value is no hard-coded to trigger load of all data.)
    }),
    columns: Ember.computed('isCogHidden', function () {
      return [{
        "template": "custom/select-box",
        "useFilter": false,
        "templateForSortCell": 'custom/select-box-all'
      }, {
        "template": "custom/componentGear",
        "isHidden": this.isCogHidden
      }, {
        "propertyName": "ComponentMerchant",
        "title": "Merchant"
      }, {
        "template": "custom/serviceLink",
        "title": "UTG Nickname",
        "sortedBy": "ComponentNickname"
      }, {
        "propertyName": "UtgStatus",
        "title": "UTG Status"
      }, {
        "propertyName": "UtgVersion",
        "title": "UTG Version"
      }, {
        "title": "Agent Version",
        "className": "columnMinWidth",
        "propertyName": "AgentVersion"
      }, {
        "title": "Configuration Status",
        "propertyName": "ConfigStatus"
      }, // {
      // 	"template": "custom/configurationVersion",
      // 	"title": "Configuration Status",
      // 	"sortedBy": "configVersion.status"
      // },
      // {
      // 	"propertyName": "MachineStatusEntityHasTasks",
      // 	"title": "Scheduled Tasks",
      // },
      {
        "propertyName": "ComponentProfileName",
        "title": "Profile"
      }, {
        "propertyName": "ComponentProfileVersion",
        "title": "Profile Version"
      }, {
        "propertyName": "ComponentLocation",
        "title": "Location/Serial"
      }, {
        "propertyName": "ComponentLane",
        "title": "Lane"
      }, {
        "propertyName": "ComponentFileId",
        "title": "File ID"
      }, {
        "propertyName": "MachineDnsName",
        "title": "Machine DNS Name"
      }, {
        "propertyName": "MachineStatus",
        "title": "Machine Status"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "MachineStatusEntityLastKnownCommunication",
        "title": "Last Known Communication",
        "sortedBy": "MachineStatusEntityLastKnownCommunication"
      }, {
        "template": "custom/dateFormatter",
        "propertyName": "MachineStatusEntityNextPredictedCommunication",
        "title": "Next Predicted Communication",
        "sortedBy": "MachineStatusEntityNextPredictedCommunication"
      }, {
        "propertyName": "MachineNickname",
        "title": "Machine Nickname"
      }, {
        "propertyName": "ParentName",
        "title": "Parent Account"
      } // {
      //   "propertyName": "isDuplicate",
      //   "title": "Possible Duplicate"
      // }
      ];
    }),
    hasPermissionToEditServices: Ember.computed('session.selectedBusinessEntity', function () {
      var hasPermissionToEditServices = false;
      var selectedBusinessEntity = this.get('session.selectedBusinessEntity');
      var user = this.get('session.userObject');
      hasPermissionToEditServices = user.hasPermission('canEdit', 'in4mUtgServices', selectedBusinessEntity);
      return hasPermissionToEditServices;
    }),
    getProfiles: Ember.observer('model', function () {
      var _this = this;

      _this.set('profilesLoaded', false);

      if (this.hasPermissionToEditServices) {
        var selectedBusinessEntity = this.get('session.selectedBusinessEntity.id');
        this.store.query('componentProfile', {
          parentBusinessEntityId: selectedBusinessEntity
        }).then(function (componentProfiles) {
          _this.set('componentProfiles', componentProfiles);

          _this.set('profilesLoaded', true);
        }, function (error) {
          _this.set('profilesLoaded', false);
        });
      }
    }),
    actions: {
      openUtgLink: function openUtgLink(record) {
        // event._target.target.stopPropagation()
        var beId = record.BusinessEntityId;
        var componentId = record.ComponentId; // window.open('/react/#/be/{{record.BusinessEntityId}}/utgs/{{record.ComponentId}}')

        window.open("/react/#/be/".concat(beId, "/utgs/").concat(componentId));
        return false;
      },
      //-----------------------------------------------------------------
      // Filter Modal
      //-----------------------------------------------------------------
      openFilterModal: function openFilterModal() {
        $('#searchFilterModal').modal('show');
      },
      cancelFilter: function cancelFilter() {
        $('#searchFilterModal').modal('hide');
        this.set('filter', {
          "Predicates": []
        });
      },
      applyFilter: function applyFilter(data) {
        var _this = this;

        console.log('applyFilter');
        console.log(JSON.stringify(data, null, 2));
        $('#searchFilterModal').modal('hide');
        var beId = this.get('session.selectedBusinessEntity.id');
        var restQuery = this.get('restQuery');
        restQuery.setComponentsFilter(data); // call the rest-query service with our query parameters to get the filtered data from the rest api

        $('#progressHudOverlay').show();
        restQuery.queryComponents(beId).then(function (components) {
          $('#progressHudOverlay').hide();
        }).catch(function (err) {
          $('#progressHudOverlay').hide();
          return _this.get('errorhandler').handleError(err, 'Error while making a request.');
        });
      },
      //-----------------------------------------------------------------
      // Remove From Command Center Modal
      //-----------------------------------------------------------------
      showDeleteMachineWarningModal: function showDeleteMachineWarningModal(record) {
        if (this.selectedComponents.length === 0) {
          var tempArray = [];
          tempArray.pushObject(record);
          this.set('selectedComponents', tempArray);
        }

        this.set('userConfirmDelete', '');
        $('#deleteMachineWarningModal').modal('show');
      },
      dismissDeleteMachineModal: function dismissDeleteMachineModal() {
        console.log('dismissDeleteMachineModal');
        $('#deleteMachineWarningModal').modal('hide');
        $('#deleteMachineConfirmationModal').modal('hide');
        this.set('userConfirmDelete', '');
      },
      deleteMachineAction: function deleteMachineAction() {
        var _this = this;

        var promises = [];
        var records = this.get('selectedComponents');
        var userConfirmDelete = this.get('userConfirmDelete').trim().toUpperCase();
        var validator = $('#deleteMachineForm').validate();

        if ('DELETE' !== userConfirmDelete) {
          validator.showErrors({
            'userConfirmDelete': 'Please enter DELETE into the textbox to confirm.'
          });
        } else {
          $("#deleteMachineForm").data('validator').resetForm();
          $('#progressHudOverlay').show();
          records.forEach(function (record) {
            // load machine object from ember data to ensure we have an object that it can remove
            promises.push(_this.store.findRecord('machine', record.MachinePermanentId).then(function (machine) {
              promises.push(machine.destroyRecord());
            }));
          }); //after all the requests are done, you either get a error or success

          Ember.RSVP.all(promises).then(function () {
            $('#progressHudOverlay').hide();

            _this.send('dismissDeleteMachineModal');

            _alerts.default._success('The selected services were removed. Refresh the page to view the updates.', 'Service Removal');
          }, function (error) {
            console.log(error);
            $('#progressHudOverlay').hide();

            _alerts.default._error('The selected serviced could not be removed.', 'Service Removal');

            _this.send('dismissDeleteMachineModal');
          });
        }
      },
      //-----------------------------------------------------------------
      // Export
      //-----------------------------------------------------------------
      exportComponents: function exportComponents() {
        var r = confirm("Export may take a few minutes to download ALL components on this tab. Are you sure you want to continue?"); //Note: The data generated for export from the machines tab and components tab is now identical. Therefore, the code from here down is IDENTICAL in each.

        if (r == true) {
          var _this = this;

          _alerts.default._info('Download of components.csv should begin shortly.'); // build url


          var beId = this.get('session.selectedBusinessEntity.id');

          var baseUrl = _this.get('apiPath.in4mApiUrl') + '/' + _this.get('apiPath.in4mApiPath');

          var downloadUrl = baseUrl + "/businessentities/".concat(beId, "/components/query/csv");
          var authHeaderValue = 'Token token="' + this.get('session.sessionObject.accessToken') + '"'; //trigger Ajax request to load binary data

          var oReq = new XMLHttpRequest();
          oReq.open("POST", downloadUrl, true);
          oReq.setRequestHeader('Authorization', authHeaderValue); //When using setRequestHeader(), you must call it after calling open(), but before calling send(). If this method is called several times with the same header, the values are merged into one single request header. (See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader)

          oReq.responseType = "arraybuffer";
          oReq.setRequestHeader('Content-Type', 'application/json');
          oReq.setRequestHeader('Accept', 'application/json');
          /* For more information on "Receiving binary data using JavaScript typed arrays",
             See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          */

          oReq.onload = function (oEvent) {
            var arrayBuffer = oReq.response; // Note: not oReq.responseText

            if (arrayBuffer) {
              var byteArray = new Uint8Array(arrayBuffer);
              /* Package data as Blob for download. See:
                - https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
                - https://developer.mozilla.org/en-US/docs/Web/API/Blob)
              */

              var blob = new Blob([byteArray], {
                type: "application/octet-stream"
              }); //trigger save prompt

              saveAs(blob, "components.csv");
            }
          };

          var restQuery = this.get('restQuery'); // double stringify is required to ensure the data is encoded the way the api expects

          var encodedData = JSON.stringify(restQuery.componentsFilter).replace("\n", "");
          oReq.send(JSON.stringify(encodedData));
        }
      },
      filterApplied: function filterApplied(filteredData) {
        this.set('model', filteredData);
      },
      updateStatusInstructions: function updateStatusInstructions(value) {
        this.set('selectedStatus', value.selectedStatus);
        this.set('instructions', value.instructions);
      },
      displayDataChanged: function displayDataChanged(settings) {
        sessionStorage.setItem('pageSize', settings.pageSize);
        sessionStorage.setItem('pageNumber', settings.currentPageNumber);
        this.set('selectedComponents', settings.selectedItems);
      },
      applyColumnHide: function applyColumnHide(processedColumns) {
        var hiddenColumnCount = 0;
        processedColumns.forEach(function (column, index, array) {
          var visible = column.get('mayBeHidden');
          column.set('isHidden', !visible);

          if (!visible) {
            hiddenColumnCount = hiddenColumnCount + 1;
          }

          if (index === array.length - 1) {
            //sending column visibility action only after looping through all objects
            var firstColumn = processedColumns[0]; //

            var secondColumn = processedColumns[1];

            if (hiddenColumnCount === array.length - 2) {
              //to make sure first (checkbox) and second (gear icon) columns are hidden when the rest of the columns are hidden
              firstColumn.set('isHidden', true);
              secondColumn.set('isHidden', true);
            } else {
              firstColumn.set('isHidden', false);
              secondColumn.set('isHidden', false);
            }
          }
        });
      },
      scheduleTask: function scheduleTask(record) {
        if (this.selectedComponents.length === 0) {
          var tempArray = [];
          tempArray.pushObject(record);
          this.set('selectedComponents', tempArray);
        }

        this.set('initializeDatePicker', true);
        $('#scheduleTaskModal').modal('show');
      },
      //-----------------------------------------------------------------
      // Profile Modal
      //-----------------------------------------------------------------
      showApplyProfileModal: function showApplyProfileModal(record) {
        if (this.selectedComponents.length === 0) {
          var tempArray = [];
          tempArray.pushObject(record);
          this.set('selectedComponents', tempArray);
        }

        $('#applyProfileModal').modal('show');
      },
      closeApplyProfileModal: function closeApplyProfileModal() {
        this.set('selectedProfileId', '');
        $('#applyProfileModal').modal('hide');
        $("#applyProfile").data('validator').resetForm();
        $('#progressHudOverlay').hide();
      },
      applyProfile: function applyProfile() {
        if ($('#applyProfile').valid()) {
          $('#progressHudOverlay').show();

          var _this = this;

          var selectedProfile = _this.get('componentProfiles').findBy("id", this.selectedProfileId);

          var selectedProfileName = selectedProfile.get('name');

          var selectedComponents = _this.get('selectedComponents');

          var promises = []; //create a new componentProfileAssignmentCollection that holds all the componentProfileAssignments

          var componentProfileAssignmentCollection = _this.store.createRecord('componentProfileAssignmentCollection');

          selectedComponents.forEach(function (component, index) {
            var componentProfileAssignment = _this.store.createRecord('componentProfileAssignment', {
              componentId: component.id,
              componentBusinessEntityId: component.BusinessEntityId,
              profileId: selectedProfile.get('id'),
              profileBusinessEntityId: selectedProfile.get('parentBusinessEntity.id'),
              componentProfileAssignmentCollection: componentProfileAssignmentCollection
            });

            componentProfileAssignmentCollection.get('componentProfileAssignments').addObject(componentProfileAssignment); //change the number to the number, of requests services can handle at a time

            var numberOfRequestsHandledAtServer = 5;

            if (selectedComponents.length > numberOfRequestsHandledAtServer) {
              if (index > 0 && index % 5 === numberOfRequestsHandledAtServer - 1 || index === selectedComponents.length - 1) {
                promises.push(componentProfileAssignmentCollection.save().then(function (response) {//do nothing
                }, function (error) {
                  Ember.RSVP.reject();
                }));
                componentProfileAssignmentCollection = _this.store.createRecord('componentProfileAssignmentCollection');
              }
            } else {
              if (index === selectedComponents.length - 1) {
                promises.push(componentProfileAssignmentCollection.save().then(function (response) {//do nothing
                }, function (error) {
                  Ember.RSVP.reject();
                }));
              }
            }
          }); //after all the requests are done, you either get a error or success

          Ember.RSVP.all(promises).then(function () {
            _alerts.default._success(selectedProfileName + ' has been applied successfully.', 'Profile Applied');

            _this.send('closeApplyProfileModal'); // update data to reflect the changes to the user


            var beId = _this.get('session.selectedBusinessEntity.id');

            var restQuery = _this.get('restQuery');

            restQuery.queryComponents(beId);
          }, function () {
            _alerts.default._error(selectedProfileName + ' was not applied to the component. Please verify that the profile selected is a complete and valid profile. If the issue persists, contact your account administrator.', 'Profile Application Failed');

            _this.send('closeApplyProfileModal');
          });
        }
      },
      editParent: function editParent(record) {},
      editProfile: function editProfile(record) {},
      selectedDate: function selectedDate(date) {
        var formattedDate = date.format(this.fmt);
        this.set('selectedDate', formattedDate);
      },
      closeModal: function closeModal() {
        $('#daterangepicker').val(''); //resetting the daterangepicker value to default value

        $('#fromdaterangepicker').val('');
        $('#todaterangepicker').val('');
        this.set('selectedStatus', '');
        this.set('selectedDate', '');
        this.set('selectedTimeZone', '');
        this.set('fromdaterangepicker', '');
        this.set('todaterangepicker', '');
        this.set('casenumber', '');
        this.set('runImmediately', false);
        $('#scheduleTaskModal').modal('hide');
        this.set('initializeDatePicker', false);
        this.set('selectedComponentType', '');
        this.set('selectedTaskType', '');
        this.set('instructions', '');
        $("#scheduleTasks").data('validator').resetForm(); //resetting the form to remove error classes if any on modal close

        $('#scheduleTaskModal').modal('hide');
      },
      changeStatus: function changeStatus(value) {
        if ($('form').valid()) {
          $('#progressHudOverlay').show();

          if (!this.get('runImmediately')) {
            //set runAfter only if run immediately not checked
            this.set('runAfter', moment(this.get('selectedDate')).tz(this.get('selectedTimeZone')).utc().format(this.get('fmt')));
          } else {
            this.set('runAfter', moment().toISOString()); //setting runAfter to current date and time to avoid sorting on null record
          }

          if (value && value.selectedStatus == '15') {
            // override instructions for upload log and trace
            this.set('instructions', value.instructions);
          }

          if (this.get('selectedStatus')) {
            this.createTaskRecords(this.get('selectedComponents'));
          }
        }
      },
      //-----------------------------------------------------------------
      // About Components / Reference Guide
      //-----------------------------------------------------------------
      componentsInfo: function componentsInfo() {
        $('#componentsInfoModal').modal('show');
      },
      showComponentsInfoDoc: function showComponentsInfoDoc() {
        var _this = this;

        window.open(_this.get('apiPath.referenceDocumentUrl'), '_blank');
      }
    },
    createTaskRecords: function createTaskRecords(components) {
      var _this = this;

      var noOfUpdatesSucceeded = 0;
      var noOfUpdatesFailed = 0;
      var promises = [];

      if (components.length == 0) {
        return;
      }

      components.forEach(function (viewComponent) {
        // taskRecord.set('component.hasConfigChanges', false); //adding this to make sure the component has no config changes when user updates UTG to latest configuration
        var taskRecord = _this.store.createRecord('task', {
          componentId: viewComponent.ComponentId,
          type: parseInt(_this.selectedStatus),
          businessEntityId: viewComponent.BusinessEntityId,
          machinePermanentId: viewComponent.MachinePermanentId,
          runAfter: _this.runAfter,
          instructions: _this.instructions
        });

        taskRecord.save();
        noOfUpdatesSucceeded = noOfUpdatesSucceeded + 1;
        return taskRecord;
      });
      Ember.RSVP.all(promises).then(function (results) {}).catch(function (error) {
        console.log('Error creating tasks', error);

        _alerts.default._error('Failed to schedule tasks for one or more UTG(s).');
      }).finally(function (results) {
        // here we only care about showing the final results to the user
        _this.showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, components);
      });
    },
    showAlert: function showAlert(noOfUpdatesSucceeded, noOfUpdatesFailed, components) {
      //passing filtered components instead of selected components
      var _this = this;

      if (noOfUpdatesSucceeded + noOfUpdatesFailed === components.length) {
        //checking components length instead of all selected components length because schedule tasks can be done for only components that have hasConfigChanges or all selected components
        if (noOfUpdatesSucceeded > 0) {
          _alerts.default._success('Task scheduled for ' + noOfUpdatesSucceeded + ' UTG(s).');
        }

        if (noOfUpdatesFailed > 0) {
          _alerts.default._error('Failed to schedule task for ' + noOfUpdatesFailed + ' UTG(s).');
        }

        $('#progressHudOverlay').hide();

        _this.send('closeModal');
      }
    }
  });

  _exports.default = _default;
});