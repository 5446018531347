define("in4m-web/templates/custom/service-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D1uhjaq6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-link\"],[11,\"title\",\"Open the UTG in a new tab\"],[9],[1,[25,[\"record\",\"ComponentNickname\"]],false],[3,\"action\",[[24,0,[]],\"sendAction\",\"utgLink\",[25,[\"record\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "in4m-web/templates/custom/service-link.hbs"
    }
  });

  _exports.default = _default;
});